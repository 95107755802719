import React from 'react';
import Script from 'nordic/script';
import PropTypes from 'prop-types';
import { suScopePath } from '../../helpers';
import { NOTE_CONFIG_KEYS_FIRST_STEP_MODAL } from '../../helpers/constants';
import { floxBrowserView } from '../../flox/flox-browser-view';
import TracksContext from '../../context/tracks-context';
import { tabTitle } from '../../helpers/tabTitle';

const { ContextualCoachWrapper } = require('seller-journey-ui');
const { modes } = require('seller-journey-ui/src/constants');
const env = require('nordic/env');

/**
 * Get server state
 */
const tracking = {
  analytics: { analyticsDimensions: {} },
  melidataEventData: '',
};

const Note = props => {
  const { floxPreloadedState, auth, scopeProd, baseUrl } = props;
  let { FloxView } = props;
  if (!FloxView) FloxView = floxBrowserView(floxPreloadedState);

  return (
    <ContextualCoachWrapper
      mode={modes.FIRST_STEPS_MIX_MODE}
      flowKey={
        auth && auth.user
          ? NOTE_CONFIG_KEYS_FIRST_STEP_MODAL[!scopeProd ? 'DEV' : 'PROD'].UNI_CONTENT_LOGGED
          : NOTE_CONFIG_KEYS_FIRST_STEP_MODAL[!scopeProd ? 'DEV' : 'PROD'].UNI_CONTENT
      }
      referrer="desktop"
      clientSide
      baseURL={`${baseUrl}/api`}
      withAnimation={false}
      adjustToFooter
    >
      <Script on="now">{suScopePath}</Script>
      <TracksContext.Provider
        analyticsDimensions={tracking.analytics.analyticsDimensions}
        melidataEventData={tracking.melidataEventData}
        performEvent={() => ''}
      >
        <FloxView />
      </TracksContext.Provider>
    </ContextualCoachWrapper>
  );
};

Note.propTypes = {
  FloxView: PropTypes.elementType,
  floxPreloadedState: PropTypes.shape({}),
  auth: PropTypes.shape({
    user: PropTypes.shape({}),
  }),
  scopeProd: PropTypes.bool,
  baseUrl: PropTypes.string,
};

export const hydrate = 'hydrateRoot';

export default Note;

export const getServerSideProps = async (req, res) => {
  const { platform, auth, baseUrl } = req;
  const { floxPreloadedState, FloxView, title } = res.locals.state;
  const scopeProd = env.SCOPE === 'prod';

  return {
    props: {
      floxPreloadedState,
      FloxView,
      auth,
      scopeProd,
      baseUrl,
    },
    settings: {
      title: tabTitle(platform, title),
    },
  };
};

export const setPageSettings = ({ settings }) => ({
  title: settings.title,
  className: 'sc-note',
});
